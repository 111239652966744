<template>
  <div class="d-flex flex-column flex-root">
    <div
      class="login login-1 d-flex flex-column flex-lg-row flex-column-fluid bg-white"
      :class="{
        'login-signin-on': this.state == 'signin',
        'login-signup-on': this.state == 'signup',
        'login-forgot-on': this.state == 'forgot',
      }"
      id="kt_login"
    >
      <!--begin::Aside-->
      <div
        class="login-aside d-flex flex-column flex-row-auto"
        style="background-color: #c31414c8"
      >
        <div class="d-flex flex-column-auto flex-column pt-lg-40 pt-15 p-3">
          <a href="#" class="text-center mb-10">
            <img
              src="media/logos/logomarca-comercial1.png"
              style="width: 60%"
              alt=""
            />
          </a>
          <!-- <h3
            class="font-weight-bolder text-center font-size-h4 font-size-h1-lg"
            style="color: #986923;"
          >
         Contabiul<br />with great build tools
          </h3> -->
        </div>
        <!-- <div
          class="aside-img d-flex flex-row-fluid bgi-no-repeat bgi-position-y-bottom bgi-position-x-center"
          :style="{ backgroundImage: `url(${backgroundImage})` }"
        ></div> -->
      </div>
      <!--begin::Aside-->
      <!--begin::Content-->
      <div
        class="login-content flex-row-fluid d-flex flex-column justify-content-center position-relative overflow-hidden p-5 mx-auto"
      >
        <div class="d-flex flex-column-fluid flex-center">
          <!--begin::Signin-->
          <div class="login-form login-signin">
            <form
              class="form"
              novalidate="novalidate"
              id="kt_login_signin_form"
              @submit.stop.prevent="onSubmitLogin()"
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg"
                >
                  Bem Vindo ao Painel Esporte Clube Comercial
                </h3>
                <!-- <span class="text-muted font-weight-bold font-size-h3"
                  >Novo Aqui?
                  <a
                    id="kt_login_signup"
                    class="text-primary font-weight-bolder"
                    @click="showForm('signup')"
                    >Cria sua Conta</a
                  ></span
                > -->
              </div>
              <div class="form-group">
                <label class="font-size-h5 font-weight-bolder text-dark"
                  >login</label
                >
                <div
                  id="example-input-group-1"
                  label=""
                  label-for="example-input-1"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="text"
                    name="login"
                    ref="login"
                    v-model="form.login"
                  />
                </div>
              </div>
              <div class="form-group">
                <div class="d-flex justify-content-between mt-n5">
                  <label class="font-size-h5 font-weight-bolder text-dark pt-5"
                    >Senha</label
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('forgot')"
                    >Esqueceu sua Senha ?</a
                  >
                  <a
                    class="text-primary font-size-h6 font-weight-bolder text-hover-primary pt-5"
                    id="kt_login_forgot"
                    @click="showForm('signup')"
                    >Crie sua Conta ?</a>
                </div>
                <div
                  id="example-input-group-2"
                  label=""
                  label-for="example-input-2"
                >
                  <input
                    class="form-control form-control-solid h-auto py-7 px-6 rounded-lg"
                    type="password"
                    name="password"
                    ref="password"
                    v-model="form.password"
                    autocomplete="off"
                  />
                </div>
              </div>
              <div class="pb-lg-0 pb-5">
                <button
                  :disabled="verif"
                  ref="kt_login_signin_submit"
                  class="btn btn-info font-weight-bolder font-size-h6 px-15 py-4 my-3 mr-3"
                >
                  Entrar
                  <v-progress-circular
                    indeterminate
                    color="success"
                    v-show="verif"
                    :value="20"
                  ></v-progress-circular>
                </button>
                <!-- <button
                  type="button"
                  class="btn btn-light-primary font-weight-bolder px-8 py-4 my-3 font-size-lg"
                >
                  <span class="svg-icon svg-icon-md">
                    <inline-svg
                      src="media/svg/social-icons/google.svg"
                    /> </span
                  >Sign in with Google
                </button> -->
              </div>
            </form>
          </div>
          <!--end::Signin-->
          <!--begin::Signup-->
          <div class="login-form login-signup">
            <createResponsaveis></createResponsaveis>
            <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
          </div>
          <!--end::Signup-->
          <!--begin::Forgot-->
          <div class="login-form login-forgot">
            <!--begin::Form-->
            <form
              class="form"
              novalidate="novalidate"
         
            >
              <div class="pb-13 pt-lg-0 pt-5">
                <h3
                  class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg"
                >
                  Esqueceu sua senha ?
                </h3>
                <p class="text-muted font-weight-bold font-size-h4">
                  Digite seu email para reset de sua senha!
                </p>
              </div>
              <div class="form-group">
                <input
                  class="form-control form-control-solid h-auto py-7 px-6 rounded-lg font-size-h6"
                  type="email"
                  placeholder="Email"
                  v-model="form_recupercao.email"
                  autocomplete="off"
                />
              </div>
              <div class="form-group d-flex flex-wrap pb-lg-0">
                <button
                  @click="onSubmitSenha()"
                  type="button"
                  id="kt_login_forgot_submit"
                  class="btn btn-primary font-weight-bolder font-size-h6 px-8 py-4 my-3 mr-4"
                >
                  Enviar
                </button>
                <button
                  type="button"
                  id="kt_login_forgot_cancel"
                  class="btn btn-light-primary font-weight-bolder font-size-h6 px-8 py-4 my-3"
                  @click="showForm('signin')"
                >
                  Cancel
                </button>
              </div>
            </form>
          </div>
          <!--end::Forgot-->
        </div>
        <!--begin::Content footer-->
        <!-- <div
          class="d-flex justify-content-lg-start justify-content-center align-items-end py-7 py-lg-0"
        >
          <a href="#" class="text-primary font-weight-bolder font-size-h5"
            >Termos</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Planos</a
          >
          <a href="#" class="text-primary ml-10 font-weight-bolder font-size-h5"
            >Contateno</a
          >
        </div> -->
        <!--end::Content footer-->
      </div>
      <!--end::Content-->
    </div>
  </div>
</template>

<!-- Load login custom page styles -->
<style lang="scss">
@import "@/assets/sass/pages/login/login-1.scss";
</style>

<script>

// FormValidation plugins
import { fireAlert } from "@/core/mixins/alertMessageMixin";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { LOGIN, LOGOUT } from "@/core/store/auth.module";
import createResponsaveis from "@/view/components/rh/createResponsaveis"
export default {
  mixins: [fireAlert],
components:{createResponsaveis},
  name: "login-1",
  data() {
    return {
      state: "signin",
      // Remove this dummy login info
      verif: false,

      form: {
        login: null,
        password: null,
      },
      form_recupercao: {
        email: "",
      },
    };
  },
  computed: {
    ...mapState({
      errors: (state) => state.auth.errors,
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.BASE_URL + "media/svg/illustrations/login-visual-2.svg"
      );
    },
  },

  methods: {
    showForm(form) {
      this.state = form;
      var form_name = "kt_login_" + form + "_form";
      KTUtil.animateClass(
        KTUtil.getById(form_name),
        "animate__animated animate__backInUp"
      );
    },

    async onSubmitLogin() {
      // clear existing errors
      await this.$store.dispatch(LOGOUT);
      this.verif = true;

      await this.$store
        .dispatch(LOGIN, this.form)
        .then(() => {
          this.fireAlert({
            ...{ message: "Login feito com sucesso", tipo: "success" },
            routeName: "dashboard",
          });
        })

        .finally(() => {
          this.verif = false;
        });
    },

   

    async onSubmitSenha() {
      // clear existing errors
      // this.$store.dispatch(LOGOUT);
      this.verif = true;
      await this.$store
        .dispatch("configUsuarios/recuperar_senha", this.form_recupercao)
        .then(() => this.$router.push({ name: "dashboard" }))

        .finally(() => {
          this.verif = false;
        });
      // this.fv.on("core.form.invalid", () => {
      //   Swal.fire({
      //     title: "",
      //     text: "Please, provide correct data!",
      //     icon: "error",
      //     confirmButtonClass: "btn btn-secondary",
      //     heightAuto: false
      //   });
      // });
    },
  },
};
</script>
